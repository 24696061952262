import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import parse from "date-fns/parse"
import compareAsc from "date-fns/compareAsc"
import "../styles/project/projects.sass"

function getTimeDiff(date) {
  let diff, unit;
  const dt = Date.parse(date);
  const now = new Date();

  diff = parseInt(Math.abs(dt - now)/1000);
  unit = "second";
  let calculated = false;
  if(diff>59 && !calculated){
    diff = parseInt(diff/60);
    unit = "min";
  } else {calculated = true;}
  if(diff>59 && !calculated){
    diff = parseInt(diff/60);
    unit = "hour";
  } else {calculated = true;}
  if(diff>23 && !calculated){
    diff = parseInt(diff/24);
    unit = "day";
  } else {calculated = true;}
  if(diff>6 && !calculated){
    diff = parseInt(diff/7);
    unit = "week";
  } else {calculated = true;}
  if(diff>3 && !calculated){
    diff = parseInt(diff/4);
    unit = "month";
  } else {calculated = true;}
  if(diff>11 && !calculated){
    diff = parseInt(diff/12);
    unit = "year";
  }

  const s = diff > 1 ? "s" : "";

  return diff + " " + unit + s + " ago";
}

export const Project = ({ title, desc, path, coverImg, lastUpdated, tags }) => (
  <div className="col-12 col-md-6 pb-2 mt-3">
    <Link to={path} className="no-underline" id="path">
      <div className="grow col-12 px-0 mx-auto row project-container shadow">
        <div className="col-12 col-lg-5 m-0 p-0">
          <Img
            fluid={coverImg.childImageSharp.fluid}
            objectFit="cover"
            className="project-image"
            style={{ width: "100%", height: "100%", maxHeight: 220 }}
          />
        </div>
        <div className="col-12 col-lg-7 mt-3">
          <h4 className="mb-1 mt-0"><b>{title}</b></h4>
          <h6>Updated {getTimeDiff(lastUpdated)}</h6>
          <div className="project-tag-container mb-1">
            {tags.map((tag) => (
              <div key={title+"-"+tag} className="project-tag text-nowrap my-1 mr-2">
                <p className="mb-0" >
                  {tag}
                </p>
              </div>
            ))}
          </div>
          <p className="mb-3 mt-0">{desc}</p>
        </div>
      </div>
    </Link>
  </div>
)

export default ({ data }) => {
  let { edges } = data.allMdx // data.markdownRemark holds our post data

  return (
    <div style={{backgroundColor: "#f8f9fa"}}>
    <Layout>
      <SEO title="Projects" />
      <h2>Projects</h2>
      <div className="px-3 px-lg-0" >
        <div className="container-small row">
          {edges
            .sort((a, b) => {
              var resultA = parse(
                a.node.frontmatter.startDate,
                "yyyy-MM-dd",
                new Date()
              )
              var resultB = parse(
                b.node.frontmatter.startDate,
                "yyyy-MM-dd",
                new Date()
              )
              return compareAsc(resultB, resultA)
            })
            .map((item) => (
              <Project
                {...item.node.frontmatter}
                key={item.node.frontmatter.title}
              />
            ))}
        </div>
      </div>     
    </Layout>
    </div>
  )
}

export const pageQuery = graphql`
  query Projects {
    allMdx(filter: { frontmatter: {public: {eq: true}, type: { eq: "Project" } } }) {
      edges {
        node {
          id
          frontmatter {
            type
            title
            desc
            tags
            startDate
            lastUpdated
            path
            coverImg {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`